import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Home/:tab?/:researchId?',
    name: 'Homme',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/type2/:testTypeId',
    name: 'Type2research',
    component: () => import(/* webpackChunkName: "about" */ '../views/Type2.vue')
  },
  {
    path: '/type3/:testTypeId',
    name: 'Type3research',
    component: () => import(/* webpackChunkName: "about" */ '../views/Type3.vue')
  },
  {
    path: '*',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/', '/login','/redirect'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.token != null// localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    if(to.path != '/')
      return next('/?p='+to.path)
    return next('/');
  }

  next();
})

export default router
