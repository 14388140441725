import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import NumberFormat from '@/mixins/NumberFormat';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(NumberFormat)

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
// import { required } from 'vee-validate/dist/rules';

// extend('required', {
//   ...required,
//   message: 'Dieses Feld'
// });
import VueGoogleCharts from 'vue-google-charts'

Vue.use(VueGoogleCharts)

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
