<template>
  <div class="home d-flex justify-center  flex-column">
    <h2>Gestalten Research Admin Login</h2>
    <div>
      <v-btn @click="login" :loading="loading">Login</v-btn>
    </div>
  </div>
</template>

<script>
import {AdminApi} from '@/api/AdminApi';

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      AdminApi,
      loading: false
    }
  },
  mounted() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const code = params.get('code');
    const state = params.get('userState')
    if (code && state == 'Authenticated') {
      this.loading = true
      AdminApi.getToken(code).then(r => {
        this.loading = false
        if (r) {
          if ((sessionStorage.redirectOnLogin || '') != '') {
            this.$router.push(sessionStorage.redirectOnLogin)
            delete sessionStorage.redirectOnLogin
          } else {
            this.$router.push(`/home`)
          }
        } else {
          alert('Login fehlgeschlagen!')
        }
      })
    }
  },
  methods: {
    login() {
      const urlParams = new URLSearchParams(window.location.search);
      sessionStorage.redirectOnLogin = urlParams.get('p') || ''
      AdminApi.loginUrl(/*(urlParams.get('p')||'')*/'').then(r => {
        window.location = r.url
      })
    }
  }
}
</script>

<style lang="scss">
.home {
  height: 100%;
  align-items: center;

  > div {
    //height: 100%;
    max-width: 400px;
    //margin: 0 auto;
  }
}
</style>
