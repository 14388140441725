class Semaphore {
    constructor(initialCount) {
        this.count = initialCount;
        this.waitingPromises = [];
    }

    async acquire() {
        if (this.count > 0) {
            this.count--;
        } else {
            await new Promise((resolve) => {
                this.waitingPromises.push(resolve);
            });
        }
    }

    release() {
        this.count++;
        if (this.waitingPromises.length > 0) {
            const resolve = this.waitingPromises.shift();
            resolve();
        }
    }
}

export class AdminApi {

    static getBaseUrl() {
        return `${process.env.VUE_APP_API_HOST}`
    }

    static getHeaders() {
        if (sessionStorage.token == null) {
            return {};
        }
        return {
            'Authorization': 'Bearer ' + JSON.parse(sessionStorage.token).access_token
        }
    }

    static getRequest(path) {
        return this._doGet(`${this.getBaseUrl()}${path}`)
    }

    static deleteRequest(path) {
        return this._doDelete(`${this.getBaseUrl()}${path}`)
    }

    static postRequest(path, params = {}) {
        return this._doPost(`${this.getBaseUrl()}${path}`, params)
    }

    static plainPostRequest(path, params = {}) {
        var header = {}
        header['content-type'] = 'application/json'
        return fetch(`${this.getBaseUrl()}${path}`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(params)
        })//.then(resp => this.handleResponse(resp))
    }

    static putRequest(path, params = {}) {
        return this._doPut(`${this.getBaseUrl()}${path}`, params)
    }

    static _doGet(url) {
        return fetch(url, {
            headers: this.getHeaders()
            , credentials: 'include'
        }).then(resp => {
            return this.handleResponse(resp, '_doGet', url);
        }).catch(err => {
            return this.handleResponse(err, '_doGet', url);
        })
    }

    static _doDelete(url) {
        return fetch(url, {
            method: 'DELETE',
            headers: this.getHeaders()
            , credentials: 'include'
        }).then(resp => this.handleResponse(resp, '_doDelete', url)).catch(err => this.handleResponse(err, '_doDelete', url))
    }

    static _doPost(url, data) {
        var header = this.getHeaders()
        header['content-type'] = 'application/json'
        return fetch(url, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
            , credentials: 'include'
        }).then(resp => this.handleResponse(resp, '_doPost', url, data)).catch(err => this.handleResponse(err, this._doPost, url, data))
    }

    static _doPut(url, data) {
        var header = this.getHeaders()
        header['content-type'] = 'application/json'
        return fetch(url, {
            method: 'PUT',
            headers: header,
            body: JSON.stringify(data)
            , credentials: 'include'
        }).then(resp => this.handleResponse(resp, '_doPut', url, data)).catch(err => this.handleResponse(err, '_doPut', url, data))
    }

    static handleResponse(resp, method, url, data) {
        if (resp.ok) {
            return resp.text()
        } else {
            if (resp.status == 403 || resp.status == 401) {
                return this.refreshToken(method, url, data)
            }
        }
        alert('Mist ging wohl schief')
        return null
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    static semaphore = new Semaphore(1);

    static async refreshToken(method, url, data) {
        await this.semaphore.acquire();
        try {
            if (sessionStorage.token) {
                try {
                    await this.plainPostRequest('/auth/refresh', {token: JSON.parse(sessionStorage.token).refresh_token}).then(resp => resp.json(), err => {
                        console.log(err)
                        document.dispatchEvent(new CustomEvent('unauthorized'))
                    }).then(json => {
                        if (json.token) {
                            var tok = JSON.parse(sessionStorage.token)
                            tok.access_token = json.token
                            tok.refresh_token = json.refreshToken
                            sessionStorage.token = JSON.stringify(tok)
                        } else {
                            delete sessionStorage.token
                            document.dispatchEvent(new CustomEvent('unauthorized'))
                        }
                    })
                } catch (e) {
                    console.error(e)
                    delete sessionStorage.token
                    document.dispatchEvent(new CustomEvent('unauthorized'))
                }
            } else {
                delete sessionStorage.token
                document.dispatchEvent(new CustomEvent('unauthorized'))
            }
        } finally {
            this.semaphore.release();
        }
        if (sessionStorage.token) {
            return this[method](url, data)
        } else {
            return null
        }
    }

    static loginUrl(path) {
        return fetch(`${this.getBaseUrl()}/auth/loginurl`+(path != '' ? '?p='+path:'')).then(r => r.json())
    }

    static getToken(token) {
        return this.plainPostRequest('/auth/login', {token}).then(r => r.json()).then(json => {
            if (json.access_token) {
                sessionStorage.token = JSON.stringify(json)
                return true
            } else {
                return false
            }
        })
    }

    static login(username, password, errorCallback) {
        return this.plainPostRequest('/auth/login', {username, password}).then(resp => {
            if (resp.ok) {
                return resp.json()
            }
            errorCallback(resp)
        }).then(r => {
            sessionStorage['t'] = r.token
            sessionStorage['r'] = r.refreshToken
            sessionStorage['username'] = r.name
        })
    }

    static fetchResearches() {
        return this.getRequest('/admin/research').then(r => JSON.parse(r))
    }

    static fetchResearchDetails(id, filter) {
        return this.postRequest('/admin/research/' + id, filter).then(r => JSON.parse(r))
    }

    static createResearch(ob) {
        return this.postRequest('/admin/research', ob).then(r => JSON.parse(r))
    }

    static updateResearch(id, ob) {
        return this.putRequest('/admin/research/' + id, ob).then(r => JSON.parse(r))
    }

    static toggleOpenStateResearch(ob) {
        return this.postRequest(`/admin/research/${ob}/close`)
    }

    static hideResearch(ob) {
        return this.postRequest(`/admin/research/${ob}/hide`)
    }

    static createResearchType(ob) {
        return this.postRequest('/admin/researchtype', ob).then(r => JSON.parse(r))
    }

    static updateResearchType(id, ob) {
        return this.putRequest(`/admin/researchtype/${id}`, ob).then(r => JSON.parse(r))
    }

    static copyMedia(id, targetQuestion) {
        return this.putRequest(`/media/${id}/copy/${targetQuestion}`, {})
    }

    static fetchResearchTypes() {
        return this.getRequest('/admin/researchtype').then(r => JSON.parse(r))
    }

    static fetchResearchType(id) {
        return this.getRequest(`/admin/researchtype/${id}`).then(r => {
            return JSON.parse(r);
        })
    }

    static deleteQuestion(id, question) {
        return this.deleteRequest(`/admin/researchtype/${id}/question/${question.id}`).then(r => JSON.parse(r))
    }

    static exportCSV(id) {
        var header = this.getHeaders()
        header['content-type'] = 'application/json'
        return fetch(`${this.getBaseUrl()}/admin/research/${id}/export-csv`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({})
            , credentials: 'include'
        }).then(resp => resp.blob())
    }
    static fetchMetadata(id) {
        return this.getRequest(`/admin/research/${id}/metadata`).then(r => JSON.parse(r))
    }

    static clearResearch(id, phrase) {
        return fetch(`${this.getBaseUrl()}/admin/research/${id}/clean/${phrase}`, {
            headers: this.getHeaders()
        }).then(res => res.ok)
    }

    static fetchIncompleteResearchs(id) {
        return this.getRequest(`/admin/research/${id}/incompletes`).then(r => JSON.parse(r))
    }

    static showResearchType(id) {
        return this.postRequest(`/admin/researchtype/${id}/show`).then(r => r.ok)
    }

    static hideResearchType(id) {
        return this.postRequest(`/admin/researchtype/${id}/hide`).then(r => r.ok)
    }

    static saveQuestion(typeId, questionRequest) {
        if (questionRequest.id == null) {
            return this.postRequest('/admin/researchtype/' + typeId + '/question', questionRequest).then(r => JSON.parse(r))
        }
        return this.putRequest('/admin/researchtype/' + typeId + '/question/' + questionRequest.id, questionRequest).then(r => JSON.parse(r))
    }

    static updateImage(item, event) {

        let formData = new FormData()
        formData.append('file', event.target.files[0])
        var header = this.getHeaders()
        // header['content-type'] = 'application/json'
        return fetch(`${this.getBaseUrl()}/media/${item.id}`, {
            method: 'PUT',
            headers: header,
            body: formData
        }).then(resp => this.handleResponse(resp))
    }

    static updateRow(typeId, questionId, row) {
        return this.putRequest('/admin/researchtype/' + typeId + '/question/' + questionId + '/row_number/' + row).then(r => JSON.parse(r))
    }

    static addImage(questionObject, item, event) {

        let formData = new FormData()
        formData.append('file', event.target.files[0])
        formData.append('item', JSON.stringify(item))
        formData.append('parentId', questionObject.id)
        var header = this.getHeaders()
        return fetch(`${this.getBaseUrl()}/media/`, {
            method: 'POST',
            headers: header,
            body: formData
        }).then(resp => this.handleResponse(resp))
    }
}
