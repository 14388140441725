const NumberFormat = {
    methods: {
        formatData(value) {
            if (value == 'NaN' || value == 'Infinity' || value == '-Infinity' || value == null) {
                return value
            }
            return value.toFixed(3).toString().replace('.', ',')
        }
    }
}
export default {
    install(Vue/*, options*/) {
        Vue.mixin(NumberFormat)
    }
}
